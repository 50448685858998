import React, { ReactNode } from "react";

type Props = {
  children: ReactNode;
};

const TopicPath: React.FC<Props> = (props) => {
  return (
    // NOTE: className周りでうまく画像取得できない問題があり、環境関係なく本番のR2を見に行くようにしている
    <div className="text-xs leading-[1.4] text-default-light font-medium sp:py-4 sp:px-6 sp:border-t sp:border-b sp:border-default-light/60 md:my-8 md:px-7 md:text-sm md:leading-[1.4] [&_a]:text-default [&_a]:mr-2 [&_a]:pr-[18px] [&_a]:bg-[url('https://public.fjob-kyujin.com/public/topicpath-chevron.webp')] [&_a]:bg-[center_right] [&_a]:bg-no-repeat [&_a]:bg-[length:9px_auto]">
      <ul className="flex flex-wrap md:max-w-[1040px] md:mx-auto">
        {props.children}
      </ul>
    </div>
  );
};

export default TopicPath;
